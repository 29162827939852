// Bootstrap
import * as bootstrap from 'bootstrap';
import './scss/bootstrap.scss';

// Dropzone Upload Library
import * as FilePond from 'filepond';
import 'filepond/dist/filepond.min.css';

// Importing custom scripts + styles
import './js/cookie-consent';
import './scss/styles.scss';

import './js/scripts';

// Accept HMR
if (module && module.hot) {
    module.hot.accept();
}

window.bootstrap = bootstrap;
window.FilePond = FilePond;
