import * as FilePond from 'filepond';
import de from 'filepond/locale/de-de.js';
import fr from 'filepond/locale/fr-fr.js';
import en from 'filepond/locale/en-en.js';
import it from 'filepond/locale/it-it.js';

// general scripts
document.addEventListener('DOMContentLoaded', async () => {
    // dropdown behavior (close when clicked outside)
    document.addEventListener('click', (event) => {
        const openDropdowns = document.querySelectorAll('.filter-block.open');

        openDropdowns.forEach((dropdown) => {
            if (!dropdown.contains(event.target) && !event.target.matches('.btn-filter.open')) {
                dropdown.classList.remove('open');

                document.querySelectorAll('.btn-filter').forEach((btn) => {
                    btn.classList.remove('open');
                });
            }
        });
    });

    // Filepond
    const fileUpload = document.querySelector('input[type="file"]');
    const htmlLang = document.querySelector('html').getAttribute('lang').slice(0, 2);

    
    const translations = { de: de, fr: fr, it: it }
    const translation = translations[htmlLang] || en;

    FilePond.create(fileUpload, {
        credits: false,
        storeAsFile: true,
        labelIdle: translation.labelIdle,
    });

    // Scroll Indication
    const scrollElement = document.querySelector('.vertical-scrollelement');
    if (scrollElement) {
        let hasVerticalScrollbar = scrollElement.scrollWidth > scrollElement.clientWidth;

        if (hasVerticalScrollbar) {
            document.querySelector('.scroll-indicator').style.display = 'block';
        } else {
            document.querySelector('.scroll-indicator').style.display = 'none';
        }

        // document change width event, print console log of hasVerticalScrollbar
        window.addEventListener('resize', () => {
            hasVerticalScrollbar = scrollElement.scrollWidth > scrollElement.clientWidth;
            if (hasVerticalScrollbar) {
                document.querySelector('.scroll-indicator').style.display = 'block';
            } else {
                document.querySelector('.scroll-indicator').style.display = 'none';
            }
        });
    }

    // Scroll active horizontal scroll element into view
    const activeScrollElement = document.querySelector('.vertical-scrollelement .active-scrollelement');
    if (activeScrollElement) {
        activeScrollElement.scrollIntoView({ block: 'nearest', inline: 'center' });
    }

    // E-Mail Field validation on typing, add class if valid

    function validateEmail(email) {
        const re = /\S+@\S+\.\S+/;
        return re.test(email);
    }

    const emailFields = document.querySelectorAll('form input[type="email"]');
    if (emailFields) {
        emailFields.forEach((field) => {
            field.addEventListener('keyup', () => {
                if (validateEmail(field.value)) {
                    field.classList.add('is-valid');
                } else {
                    field.classList.remove('is-valid');
                }
            });
        });
    }
});
